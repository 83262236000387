<template>
  <v-form @submit.prevent="updateItem" ref="form">
    <v-card>
      <v-card-title
        class="headline primary lighten-1 white--text justify-space-between"
      >
        <span v-if="profile">Edit administrator</span>
        <span v-if="!profile">Create administrator</span>

        <v-btn icon color="white" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-2">
        <v-overlay
          :absolute="true"
          :value="dataLoading"
          justify-center
          align-center
          opacity="0.15"
        >
          <v-progress-circular size="64" indeterminate></v-progress-circular>
        </v-overlay>
        <v-text-field
          name="title"
          :label="attributeLabels.name"
          v-model="profileForm.name"
          :rules="[
            (v) => !!v || 'Required',
            (v) => (v && v.length >= 3) || 'Should contains at least 3 symbols',
            (v) =>
              (v && v.length <= 150) || 'Should contains maximum 150 symbols',
          ]"
          type="text"
        ></v-text-field>
        <v-text-field
          name="email"
          :label="attributeLabels.email"
          v-model="profileForm.email"
          :rules="[
            (v) => !!v || 'Required',
            (v) =>
              /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(
                v
              ) || 'E-mail must be valid',
          ]"
          type="text"
        ></v-text-field>
        <v-text-field
          name="password"
          :label="attributeLabels.password"
          v-model="profileForm.password"
          type="text"
          :rules="[
            (v) => !v || v.length >= 8 || 'Should contains at least 8 symbols',
            (v) => !v || v.length <= 32 || 'Should contains maximum 32 symbols',
          ]"
        ></v-text-field>
        <v-checkbox
          name="superAdmin"
          :label="attributeLabels.role"
          v-model="profileForm.role"
          true-value="superadmin"
          false-value="admin"
        ></v-checkbox>
        <v-checkbox
          name="isActive"
          :label="attributeLabels.isActive"
          v-model="profileForm.isActive"
          :true-value="true"
          :false-value="false"
        ></v-checkbox>
        <v-alert v-if="hasErrors" type="error" elevation="0" outlined>
          <div v-for="(error, key) in errors" :key="key">
            {{ prepareErrorMessage(error, attributeLabels[key]) }}
          </div>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">Close</v-btn>
        <v-btn type="submit" color="blue darken-1" text> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import _ from "lodash";
import { UPDATE_ADMIN } from "@/store/modules/admin/actions";

const defaultValues = {
  name: null,
  email: null,
  password: null,
  role: "admin",
  isActive: true,
};

export default {
  name: "AdminForm",
  props: {
    profile: Object,
  },
  data: function () {
    return {
      dataLoading: false,
      profileForm: this.profile
        ? {
            name: this.profile.name,
            email: this.profile.email,
            password: "",
            role: this.profile.role,
            isActive: this.profile.isActive,
          }
        : _.merge({}, defaultValues),
      birthdayModal: false,
      attributeLabels: {
        name: "Login",
        email: "E-mail",
        role: "Super admin",
        isActive: "Enabled",
        password: "New password",
      },
      errors: {},
    };
  },
  computed: {
    hasErrors: function () {
      return !_.isEmpty(this.errors);
    },
  },
  methods: {
    prepareErrorMessage(error, key) {
      return error.replace("{attribute}", key);
    },
    updateItem: function () {
      if (this.$refs.form.validate()) {
        this.dataLoading = true;
        const { profileForm } = this;
        this.$store
          .dispatch("admin/" + UPDATE_ADMIN, {
            form: profileForm,
            id: this.profile ? this.profile.id : null,
          })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.result) {
                this.profileForm = defaultValues;
                this.$emit("submitted");
                this.errors = {};
              } else {
                this.errors = payload.details;
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
};
</script>
