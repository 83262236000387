<template>
  <v-form @submit.prevent="updateItem" ref="form">
    <v-card>
      <v-card-title
        class="headline primary lighten-1 white--text justify-space-between"
      >
        <span>Edit {{ profile.name }} permissions</span>
        <v-btn icon color="white" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-2" style="height: calc(100vh - 130px)">
        <v-overlay
          :absolute="true"
          :value="dataLoading"
          justify-center
          align-center
          opacity="0.15"
        >
          <v-progress-circular size="64" indeterminate></v-progress-circular>
        </v-overlay>
        <v-row justify="space-between" class="mx-3" no-gutters>
          <v-col
            cols="4"
            v-for="(resource, index) in resources"
            :key="index"
            class="pb-4 pt-1 px-3 permission-item"
          >
            <v-switch
              v-model="permissions[resource.key]"
              :label="resource.name"
              hide-details
            ></v-switch>
            <div v-if="resource.children && resource.children.length">
              <div class="d-flex">
                <div>
                  <div
                    v-for="(resourceChild, childIndex) in resource.children"
                    :key="childIndex"
                  >
                    <v-switch
                      v-model="permissions[resourceChild.key]"
                      :label="resourceChild.name"
                      :disabled="!permissions[resource.key]"
                      hide-details
                    ></v-switch>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">Close</v-btn>
        <v-btn type="submit" color="primary darken-1" text> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import _ from "lodash";
import { SET_PERMISSIONS } from "@/store/modules/admin/actions";
import { mapGetters } from "vuex";

export default {
  name: "PermissionForm",
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      dataLoading: false,
      permissions: _.cloneDeep(this.profile?.permissions ?? {}),
    };
  },
  computed: {
    ...mapGetters("admin", ["aclResources"]),
    resources: function () {
      const localResources = _.orderBy(
        _.cloneDeep(this.aclResources),
        [
          (o) => {
            return o.parentId || "";
          },
        ],
        ["asc"]
      );
      let rootResources = [];
      localResources.forEach(function (resource) {
        if (resource.parentId) {
          const parentResource = _.find(localResources, {
            id: resource.parentId,
          });
          if (parentResource.children === undefined) {
            parentResource.children = [];
          }
          parentResource.children.push(resource);
        } else {
          rootResources.push(resource);
        }
      });
      return rootResources;
    },
  },
  methods: {
    updateItem: function () {
      if (this.$refs.form.validate()) {
        this.dataLoading = true;
        const { permissions } = this;
        this.$store
          .dispatch("admin/" + SET_PERMISSIONS, {
            form: permissions,
            id: this.profile ? this.profile.id : null,
          })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.result) {
                this.$emit("submitted");
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
};
</script>

<style>
.permission-item {
  border-top: 1px dashed rgba(0, 0, 0, 0.12);
  padding-bottom: 15px;
}
.permission-item:nth-child(1),
.permission-item:nth-child(2),
.permission-item:nth-child(3) {
  border-top: none;
}
.permission-item:nth-child(3n + 2),
.permission-item:nth-child(3n) {
  border-left: 1px dashed rgba(0, 0, 0, 0.12);
}
</style>
