<template>
  <v-col sm="10" md="8" lg="4">
    <v-autocomplete
      :items="aclResources"
      item-text="name"
      item-value="key"
      :loading="isLoading"
      :search-input.sync="search"
      menu-props="closeOnContentClick"
      @change="search = ''"
      multiple
      deletable-chips
      hide-no-data
      name="resource"
      :label="label"
      v-model="itemValue"
      @input="handleInput"
    >
      <v-icon
        v-if="removeble"
        slot="append"
        title="Remove filter"
        @click="$emit('remove')"
      >
        mdi-close
      </v-icon>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AllowedResourceSearch",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {},
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    removeble: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
      closeOnContentClick: true,
      //List of selected related items
      //Filter values status
      isLoading: false,
      //Filter values
      items: [],
      //Filter entered value
      search: "",
    };
  },
  computed: {
    ...mapGetters("admin", ["aclResources"]),
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
  },
};
</script>
