<template>
  <div>
    <v-row>
      <v-col>
        <h1 class="outline--text">Administrators</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn
          v-if="canManage"
          small
          color="primary"
          rounded
          @click="editItem(null)"
        >
          Create administrator
        </v-btn>
      </v-col>
    </v-row>
    <admin-search></admin-search>
    <v-row>
      <v-col class="outline--text mt-7" style="max-width: 700px">
        <div>
          <span v-if="!isLoading && this.totalCount > 0">
            <p v-if="this.totalCount > this.currentPage * this.itemsPerPage">
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.currentPage * this.itemsPerPage
              }}
              of {{ this.totalCount }} items
            </p>
            <p v-else>
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.totalCount
              }}
              of {{ this.totalCount }} items
            </p>
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="showPages && pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
              v-model="pagination.rowPerPage"
              item-text="label"
              item-value="attribute"
              :items="countPerPage"
              label="Show items"
              style="max-width: 80px"
            >
            </v-select>
          </div>
          <div class="d-flex">
            <v-select
              item-text="label"
              item-value="attribute"
              :items="sortAttributes"
              label="sortBy"
              v-model="sort.sortBy"
              style="max-width: 180px"
            ></v-select>
            <span v-if="sort.sortBy" class="mt-5 ml-2">
              <v-icon
                v-if="sort.desc"
                title="sortAsc"
                @click="sort.desc = false"
              >
                mdi-sort-descending
              </v-icon>
              <v-icon v-else title="sortDesc" @click="sort.desc = true">
                mdi-sort-ascending
              </v-icon>
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="items"
      :items-per-page="5"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
      :item-class="itemClass"
    >
      <template v-slot:item.isActive="{ item }">
        <img
          v-if="item.isActive"
          class="mt-1"
          :src="require('@/assets/check-circle-outline-sm.png')"
          alt=""
        />
        <img
          v-else
          class="mt-1"
          :src="require('@/assets/close-circle-outline.png')"
          alt=""
        />
      </template>
      <template v-slot:item.Actions="{ item }">
        <v-btn
          v-if="canManage"
          x-small
          icon
          class="ml-3"
          @click="editItem(item)"
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="canManage && currentUser.id !== item.id"
          x-small
          icon
          class="ml-3"
          @click="editPermission(item)"
          :disabled="item.role === 'superadmin'"
        >
          <v-icon>mdi-tune-variant</v-icon>
        </v-btn>
        <v-btn
          v-if="canManage && currentUser.id !== item.id"
          x-small
          icon
          class="ml-3"
          @click="confirmItem(item)"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-if="selectedItem" v-model="deleteDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between py-1"
        >
          Delete confirmation
          <v-btn icon color="white" @click="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-card-text class="mt-6">
          Are you sure you want to delete {{ selectedItem.name }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false">Close</v-btn>
          <v-btn color="primary" text @click="deleteItem(selectedItem)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="450px">
      <admin-form
        v-if="editDialog"
        :profile="selectedItem"
        @close="closeEditDialog"
        @submitted="
          fetchItems();
          closeEditDialog();
        "
      ></admin-form>
    </v-dialog>
    <v-dialog v-model="permissionDialog" fullscreen>
      <permission-form
        v-if="permissionDialog"
        :profile="selectedItem"
        @close="permissionDialog = false"
        @submitted="
          fetchItems();
          permissionDialog = false;
        "
      ></permission-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import BaseListPage from "./base/BaseListPage";

import {
  defaultFilters,
  emptyFilters,
  multipleFilters,
} from "@/store/modules/admin/state";
import {
  FETCH_ADMINS,
  FETCH_RESOURCES,
  SET_FILTERS,
  DELETE_ADMIN,
} from "@/store/modules/admin/actions";

import AdminSearch from "@/views/admin/list/AdminSearch";
import AdminForm from "@/views/admin/item/AdminForm";
import PermissionForm from "@/views/admin/item/PermissionForm";
import acl from "@/mixin/acl";
import { RESOURCE_ADMINS_MANAGEMENT } from "@/store/modules/admin/getters";

export default {
  name: "AdminListPage",
  mixins: [acl],
  extends: BaseListPage,
  components: { PermissionForm, AdminSearch, AdminForm },
  props: {
    limit: {
      required: false,
    },
  },
  data() {
    return {
      defaultFilters,
      emptyFilters,
      multipleFilters,
      showPages: false,
      deleteDialog: false,
      permissionDialog: false,
      countPerPage: [
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
      headers: [
        {
          text: "Id",
          value: "id",
          width: "100",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "E-mail",
          value: "email",
        },
        {
          text: "Enabled",
          value: "isActive",
          width: "50",
          align: "center",
        },
        {
          text: "Role",
          value: "role",
          width: "100",
          align: "center",
        },
        {
          width: "150",
          text: "",
          value: "Actions",
        },
      ],
      sortAttributes: [
        {
          attribute: "id",
          label: "ID",
        },
        {
          attribute: "isActive",
          label: "Enabled",
        },
        {
          attribute: "name",
          label: "Name",
        },
        {
          attribute: "email",
          label: "Email",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("user", ["currentUser"]),
    ...mapGetters("admin", [
      "items",
      "aclResources",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
      "formattedFilters",
    ]),
    ...mapState("admin", ["sort", "pagination", "filters"]),
    canManage: function () {
      return this.hasAccess([RESOURCE_ADMINS_MANAGEMENT]);
    },
    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
  },
  watch: {
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    applyFilters: function (payload) {
      return this.$store.dispatch(`admin/${SET_FILTERS}`, payload.data);
    },
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store.dispatch(`admin/${FETCH_ADMINS}`).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    fetchResources: function () {
      const vm = this;
      vm.$store.dispatch(`admin/${FETCH_RESOURCES}`).then(
        () => {
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },

    confirmItem: function (item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    deleteItem: function (item) {
      this.dataLoading = true;
      this.$store
        .dispatch("admin/" + DELETE_ADMIN, item)
        .then(
          () => {
            this.selectedItem = null;
            this.deleteDialog = false;
            this.fetchItems();
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    editItem: function (item) {
      this.selectedItem = item;
      this.editDialog = true;
    },
    editPermission: function (item) {
      this.selectedItem = item;
      this.permissionDialog = true;
    },
  },
  mounted() {
    const vm = this;
    vm.applyRouteParams();
    vm.fetchItems();
    vm.fetchResources();
  },
};
</script>
